import React, { Fragment } from "react"

import Sub from "~layout/sub"
import aside from "~content/products/aside"

import { HeadingIcon } from "~styled"

const crumbs = [
  { text: "Products", to: "/products" },
  { text: "Readin English", to: "/products/readin/" },
]

const ReadinPage = () => {
  let content = (
    <Fragment>
      <HeadingIcon>
        <img src={require("~images/products/readin.png").default} alt="Readin" title="Readin" />
      </HeadingIcon>
      <h1>Introducing Readin English: The Fun Way to Learn English</h1>
      
      <p>Looking for a fun and effective way to learn English? Look no further than Readin English! Our innovative language learning platform is designed to help you improve your reading and speaking skills in a way that is both enjoyable and effective. Read on to discover the benefits of Readin, how it works, and what sets us apart from other language learning tools.</p>

      <h5>Benefits of Readin</h5>
      <p><strong>Adaptive Learning</strong>: We understand that everyone learns at their own pace. That's why our platform uses the Comprehensible Input method formulated by Emeritus Prof Stephen Krashen, as well as Item Response Theory algorithms, to adapt to your reading level and provide you with personalized learning experiences. Readin uses a simple placement test to determine language proficiency of the learners and present them level-appropriated books. As learners read and respond to quiz questions, Readin unlocks higher level books.</p>
    <br/>
      <p><strong>Effective Progression</strong>: With consistent use of Readin, you'll experience rapid improvement in your English skills through consistent use. Our platform is expertly designed to facilitate quick progression, allowing you to read and speak like a pro in the shortest possible time. Our comprehensive framework consists of 13 levels of difficulty, ranging from Levels 0 to 12, that are aligned with the Common European Framework of Reference (CEFR) levels pre-A1 to B2. Each level corresponds to one year of experience learning English as a Second Language, and we offer a vast selection of ebooks across diverse genres to encourage sustained reading.</p>
      <p>
        <img src={require('~images/products/Readin/Features/adaptive.png').default} className="img-responsive margin-auto product-image block" alt="Readin - Adaptive Learning" />
      </p>

      <p><strong>Storybooks</strong>: We know that reading can be a powerful tool for language learning. That's why we've sourced hundreds of captivating, beautifully illustrated storybooks and our expert editorial team carefully curated to provide learners with the best reading experience.</p>
      <p>
        <img src={require('~images/products/Readin/Features/bookshelf.jpg').default} className="img-responsive margin-auto product-image block" alt="Readin - Storybooks" />
      </p>

      <p><strong>Tools</strong>: Stuck on a word or phrase? No problem. Our platform includes full voice narration and a built-in dictionary, so you can easily look up unfamiliar words and phrases.</p>
      <p>
        <img src={require('~images/products/Readin/Features/tools.png').default} className="img-responsive margin-auto product-image block" alt="Readin - Tools" />
      </p>

      <p><strong>Analytics</strong>: We believe that progress tracking is key to effective language learning. That's why we provide detailed analytics on your reading speed, comprehension, and vocabulary usage, which can be shared with teachers and parents.</p>
      <p>
        <img src={require('~images/products/Readin/Features/analytics.png').default} className="img-responsive margin-auto product-image block" alt="Readin - Analytics" />
      </p>

      <p><strong>Fun Games</strong>: Reinforce your learning and improve your vocabulary with our fun, interactive games. These engaging activities are designed to help you practice your speaking and vocabulary skills, while having fun at the same time.</p>
      <p>
        <img src={require('~images/products/Readin/Features/fungames.png').default} className="img-responsive margin-auto product-image block" alt="Readin - Fun Games" />
      </p>

      <h5>How Readin Works</h5>
      <p><strong>Placement Test</strong>: Before you start using Readin, we'll determine your reading proficiency in three easy steps, so you can be placed in the appropriate learning level and start learning at your sweet spot.</p>
      <br/>
      <p><strong>Personalized Learning</strong>: Once you're placed in a learning level, our platform will introduce you to a self-paced, personalized learning system tailored to your abilities. With engaging, interactive content and adaptive learning tools, you'll be well on your way to becoming fluent in English in no time.</p>
      <p>
        <img src={require('~images/products/Readin/Features/reading loop.png').default} className="img-responsive margin-auto product-image block" alt="Readin - Personalized Learning" />
      </p>

      <h5>Expert Development Team</h5>
      <p>At Readin, we take language learning seriously. That's why we've assembled a team of experience application developers and expert consultants to develop and refine our platform. Our consultant team includes Prof Ng Hwee Tou and Dr Leong Mun Kew from NUS, as well as Matt Steele, former Head of Innovation and Development at ELT Div. OUP, and Dr Chung Tzemin, a research scientist at CommonTown. With this kind of expertise behind our product, you can be confident that Readin is the best way to learn English.</p>

      <h5>Start Learning with Readin Today</h5>
      <p>Ready to take your English skills to the next level? Sign up for Readin today and start learning in a fun, effective way!</p>

      <br/>
      <p className="text-center">
  <a href="https://readin.town" title="Visit Readin">
    <img src={require("~images/products/readin.png").default} alt="Readin" title="Readin" className="margin-auto img-responsive block" />
        Visit Readin
    </a>
      </p>
      <h5 className="contactus-info">Contact Us</h5>
      <p>For more information or sales enquiries, email us at <a href="mailto:biz@commontown.com" alt="" >biz@commontown.com</a> or call us at <a href="tel:6848-8900" alt="">6848-8900</a>.</p>
    </Fragment>
  )

  return <Sub title="Readin English" crumbs={crumbs} content={content} aside={aside} />
}

export default ReadinPage
